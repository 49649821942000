// @flow
import {getMyCategoriesQuery} from 'data/affiliate/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {compose, withStateHandlers} from 'recompose';

import ProductSelect from './ProductSelect';
import VariantSelect from './VariantSelect';

const ProducVariantSelect = ({data, setProductId, productId, ...rest}) => (
  <div>
    <ProductSelect
      filter={{category: data.categories[0].name}}
      value={productId}
      onChange={setProductId}
    />
    {productId && <VariantSelect productId={productId} {...rest} />}
  </div>
);

export default compose(
  withStateHandlers(
    {productId: null},
    {
      setProductId: props => value => ({productId: value}),
    }
  ),
  withQuery(getMyCategoriesQuery)
)(ProducVariantSelect);
