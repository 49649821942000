// @flow
import Switch from 'components/Switch';
import {affiliatesListQuery} from 'data/affiliate/queries';
import {categoryListQuery} from 'data/product/queries';
import withOpen from 'hoc/withOpen';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, branch, compose, lifecycle, withHandlers, withStateHandlers} from 'recompose';

import type {Value} from '../';
import {List, Wrapper} from './styled';

type Props = {
  discountId: number,
  targetGroup: 'affiliate' | 'category',
  value: Value,
  onChange: Function,
};

const DiscountSelection = ({
  isOpen,
  toggleOpen,
  discountId,
  data,
  targetGroup,
  localValues,
  handleSelection,
}) => (
  <Wrapper>
    <strong>{targetGroup === 'affiliate' ? 'Affiliates' : 'Categories'}</strong>
    <Switch value={isOpen} onChange={toggleOpen} />
    {isOpen && (
      <List>
        {data.map((item, index) => {
          const isSelected = !!localValues.filter(localValue => localValue === item.id).length;

          return (
            <div key={item.id}>
              <p>{item.name}</p>
              <Switch value={isSelected} onChange={() => handleSelection(item.id)} />
            </div>
          );
        })}
      </List>
    )}
  </Wrapper>
);

const enhancer: HOC<*, Props> = compose(
  withOpen,
  branch(
    props => props.targetGroup === 'affiliate',
    withQuery(affiliatesListQuery),
    withQuery(categoryListQuery)
  ),
  withStateHandlers(
    props => ({
      localValues: props.value[props.targetGroup],
    }),
    {
      setLocalValues: props => localValues => ({
        localValues: localValues.concat(),
      }),
      clearLocalValues: props => () => ({localValues: []}),
    }
  ),
  withHandlers({
    handleSelection: props => (selectedItem: number) => {
      const discount = {...props.value, discountId: props.discountId};

      const existingEntry = discount[props.targetGroup].find(entry => entry === selectedItem);
      if (!!existingEntry) {
        discount[props.targetGroup].splice(discount[props.targetGroup].indexOf(existingEntry), 1);
      } else {
        discount[props.targetGroup].push(selectedItem);
      }

      props.setLocalValues(discount[props.targetGroup]);
      props.onChange(discount);
    },
  }),
  lifecycle({
    componentWillMount() {
      const defaultValues = this.props.data
        .filter(
          item =>
            item.discountOptOuts.filter(optOut => optOut.discountId === this.props.discountId)
              .length >= 1
        )
        .map(result => result.id);

      const discount = {
        ...this.props.value,
        discountId: this.props.discountId,
      };
      discount[this.props.targetGroup] = defaultValues;

      this.props.setLocalValues(discount[this.props.targetGroup]);
      this.props.onChange(discount);
    },
    componentWillReceiveProps(nextProps, nextState) {
      if (nextProps.discountId !== this.props.discountId) {
        this.props.clearLocalValues();
        this.props.onChange({
          discountId: undefined,
          affiliate: [],
          category: [],
        });

        this.props.refetch();
      }
    },
  })
);

export default enhancer(DiscountSelection);
