// @flow
import {paginationFragment} from 'data/other/fragments';
import {formatFromCentum} from 'data/units/money/formatters';
import crypto from 'global/apolloClient/crypto';
import gql from 'graphql-tag';
import {path} from 'ramda';
import type {Query} from 'types/Graphql';

import {affiliateFragment} from './fragments';
import type {Affiliate} from './types';

export const affiliatesListQuery: Query<Affiliate[]> = {
  gql: gql`
    query affiliatesListQuery($filter: AffiliateFilter) {
      affiliate {
        list(filter: $filter) {
          pageInfo {
            ...paginationFragment
          }
          data {
            ...affiliateFragment
          }
        }
      }
    }
    ${paginationFragment}
    ${affiliateFragment}
  `,
  selector: ['affiliate', 'list'],
  pagination: true,
  debounceKey: 'affiliatesListQuery',
  defaultFilter: {limit: 999},
};

export const affiliateDetailQuery: Query<Affiliate> = {
  gql: gql`
    query affiliateDetailQuery($id: Int!) {
      affiliate {
        get(id: $id) {
          ...affiliateFragment
        }
      }
    }
    ${affiliateFragment}
  `,
  selector: ['affiliate', 'get'],
  fetchPolicy: 'network-only',
  debounceKey: 'affiliateDetailQuery',
  transform: data => {
    const currency = path(['location', 'country', 'currency'], data);
    return {
      ...data,
      deliveryCharge: formatFromCentum(data.deliveryCharge, currency),
      deliveryChargeThreshold: formatFromCentum(data.deliveryChargeThreshold, currency),
    };
  },
};

export const getMyCategoriesQuery: Query<Affiliate> = {
  gql: gql`
    query getMyCategoriesQuery($id: Int!) {
      affiliate {
        get(id: $id) {
          categories {
            categoryId
            name
          }
        }
      }
    }
  `,
  selector: ['affiliate', 'get'],
  defaultVariables: () => ({
    id: crypto.getParameterFromAccessToken('affiliateId'),
  }),
};
