// @flow
import Button from 'components/Button';
import Select from 'components/Select';
import {discountsListQuery} from 'data/discounts/queries';
import withQuery from 'hoc/withQuery';
import React from 'react';
import {type HOC, compose, withHandlers, withStateHandlers} from 'recompose';

import DiscountSelection from './DiscountSelection';
import {Wrapper} from './styled';

const UpdateOptOuts = ({data, value, setValue, selectedDiscount, selectDiscount, handleSubmit}) => (
  <div>
    <Select
      label=""
      name=""
      value={selectedDiscount}
      onChange={selectDiscount}
      options={data.map(option => ({
        id: option.id,
        value: option.percentage + ' %',
      }))}
    />
    <br />
    {selectedDiscount && (
      <Wrapper>
        <DiscountSelection
          discountId={selectedDiscount}
          targetGroup="affiliate"
          value={value}
          onChange={setValue}
        />
        <DiscountSelection
          discountId={selectedDiscount}
          targetGroup="category"
          value={value}
          onChange={setValue}
        />
      </Wrapper>
    )}
    <Wrapper>
      <Button kind="primary" onClick={handleSubmit} big success>
        save
      </Button>
    </Wrapper>
  </div>
);

export type Value = {
  discountId: ?number,
  affiliate: Array<number>,
  category: Array<number>,
};

const enhancer: HOC<*, {submit: Function}> = compose(
  withQuery(discountsListQuery),
  withStateHandlers(
    ({data}) => ({
      value: {
        discountId: undefined,
        affiliate: [],
        category: [],
      },
      selectedDiscount: undefined,
    }),
    {
      setValue: props => value => ({value}),
      selectDiscount: () => selectedDiscount => ({selectedDiscount}),
    }
  ),
  withHandlers({
    handleSubmit: props => () => {
      const affiliate = props.value.affiliate.map(aff => ({
        affiliateId: aff,
      }));
      const category = props.value.category.map(pg => ({
        categoryId: pg,
      }));
      const combined = affiliate.concat(category);

      const input = {
        discountId: props.value.discountId,
        discountOptOuts: combined,
      };

      return props.submit({input});
    },
  })
);

export default enhancer(UpdateOptOuts);
